import React, { useState, Fragment, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Modal, Tooltip, IconButton } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send';
import Scrollbar from './ImprovedScrollBar'; 
import Swal from 'sweetalert2'
import { connect } from 'react-redux';
import moment from 'moment';
import { ArrowBackIosRounded, ArrowForwardIosRounded, DeleteForever, EditRounded, ErrorRounded, CancelRounded, DragIndicatorRounded, DirectionsBusRounded } from '@material-ui/icons';
import Draggable from 'react-draggable';

import { HOST_WITHOUT_SLASH, NEWJOBCARD_VIEW, PUMPJOBCARDS, 
    SALES_QUOTE_COMMENT_URL, SALES_QUOTE_HOSTORY, SALES_REVIEW, SCHEDULE_JOB_CARD_CERT_OF_DEST_VALIDATION, SCOPEOFWORK_CRUD_TYPE_OF_WASTE, SCOPOFWORK_CRUD_ADDITIONAL_INFO } from '../store/endpoint';
import Api from '../store/api'
import { cl_blue, cl_green, cl_red } from './Colors';
import NewJobCard from '../screens/jobs/components/WasteJobCard';
// import WasteJobCard from '../screens/jobs/components/ViewOnlyJobCard';
// import PumpNewJobCard from '../screens/jobs/components/PumpJobCards'

import '../screens/sales/component/sales_table_components/sales_tableStyle.css';
import { updateJobReoccurList, updateJobReoccurFrqList, updateCommentsSection, updateCertOfDestJobCard } from '../screens/scheduling/common/action';
import { InputField } from '../screens/client/component/createStack/edit_detailStack';
import { //LETTERS_VALIDATION,
     NUMBER_VALIDATION_REGEX } from '../store/config';
import { fetch_client_list } from '../screens/client/action';
import { fetch_temp_clients } from '../screens/sales/common/action';
import VideoFilePreview from './VideoFilePreview';
import ReportModal from './GenerateReport/ReportModal';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalLow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '7 !important'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function TransitionsModal({
    modalStatus, 
    modalAction,
    quoteInfos,
    reopenScheduleModal,
    setQuoteHistoryInfos,
    quoteHistoryModalAction,
    fetch_clientList,
    fetch_tempClient,
    
    parentQuoteStatus=null,
    parentQuoteId=null,
    jobVal,
    fnRecall=null,
    updateQuoteArr,
    update_jobs_reoccur,
    update_jobs_reoccur_frq,

    setJobId=null,
    setTypeofWaste=null,
    setAddInfoList=null,
    fetch_quote_temp=null,
    setSOWModal=null,
    setMainTemplate=null,

    setContentLoader=null,
    commentRefreshFn=null,
    jobCardRefresfFn=null,
    updateCommentsSection_fn,
    updateCertOfDestJobCard_fn

}) {

    const classes = useStyles();

    const handleClose = () => {
        setJobCardModal(false)
        setJobCustomCardModal(false)
        modalAction(false)
        setOpenFrq(false)
        if(setContentLoader!==null)
            setContentLoader(null)
        if(reopenScheduleModal) {
            reopenScheduleModal()
        }
    };

    // Generate Report
    const handleOpenReportModal = ()=>{
        setIsReportModalOpen(true)
        
    }
    const handleCloseReportModal = () => {
        setIsReportModalOpen(false);
      };

    const { job, quote, schedule } = quoteInfos

    const [GalleryTab, setGalleryTab] = useState(1)
    const [jobCardModal, setJobCardModal ] = useState(false)
    const [pumpJobCardModal, setPumpJobCardModal ] = useState(false)

    const [jobCustomCardModal, setJobCustomCardModal] = useState(false)
    const [jobCardObj, setJobCardObj] = useState({})
    // const [jobCustomCardObj, setJobCustomCardObj] = useState({})
    const [jobCardBtnLoader, setjobCardBtnLoader] = useState(false)
    const [updatejobCardBtnLoader, setUpdatejobCardBtnLoader] = useState(false)
    const [jobCardModalType, setJobCardModalType] = useState('')
    const [openFrq, setOpenFrq] = useState(false)
    const [frqW, setFrqW] = useState(0)
    const [frqDate, setFrqDate] = useState('')

    const [salesQuoteSpinnerBtnIndex, setSalesQuoteSpinnerBTnIndex] = useState(null)
    const [sOWSpinnerBtnIndex, setSOWSpinnerBTnIndex] = useState(null)

    const [maximiseWin, setMaximiseWin] = useState(false)
    const [quoteCommentsArr, setQuoteCommentsArr] = useState([])
    const [comment, setComment] = useState('')
    const [commentEdit, setCommentEdit] = useState(null)
    const [commentDelete, setCommentDelete] = useState(false)

    //Generate Report
    const [isReportModalOpen,setIsReportModalOpen] = useState(false)
    

    const SpecialText = ({ title, value }) => {
        return (
            <div className="shdl-quotes-special-text-main">
                <p style={{ flex: 1 }} >{title}</p>
                <p style={{ flex: 0.5 }}>:</p>
                <p style={{ flex: 2, fontWeight: 'bold' }}>{value}</p>
            </div>
        )
    }

    useEffect(() => {
        if(quote){
        Api('get',`${SALES_QUOTE_COMMENT_URL}get/?quote_id=${quote.id}`).then(res => {
            console.log('quoteComment',res)
            if(res.length > 0) {
                setQuoteCommentsArr(res)
            }
            
        })
    }
    }, [quote]);

    console.log('*************** QUOTE INFOS ****************', schedule)

    const fetch_job_card = (val, tabType) => {
        setjobCardBtnLoader(true)
        fetch_clientList(tabType)
        fetch_tempClient(tabType)
        const jobCardRefFn = () =>{
            jobCardRefresfFn()
        }
        if(tabType==='pumps'){
                Api('get',`${PUMPJOBCARDS}${jobVal}/`).then(res =>{
                    console.log(res)
                    if(res && res.id){
                        setjobCardBtnLoader(false)
                        setJobCardModalType('view')
                        setJobCardObj(res)
                        setPumpJobCardModal(true)
                    }
                    else{
                        setjobCardBtnLoader(false)
                            handleClose()
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Job Card not available!'
                            }) 
                        
                    }
                })         

        }
        else{
            
            Api('get',`${NEWJOBCARD_VIEW}${val}/`).then(res =>{
                console.log(res)
                setjobCardBtnLoader(false)
                if(res && res.id){
                    if(quote && quote.client && quote.client.certificate_of_destruction_required && jobCardRefresfFn){
                        Api('post',`${SCHEDULE_JOB_CARD_CERT_OF_DEST_VALIDATION}${quote.client.id}/`).then(()=>{
                            jobCardRefFn()
                        })
        
                    }
                    setJobCardModalType('view')
                    setJobCardObj(res)
                    setJobCardModal(true)
                }else{
                        handleClose()
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Job Card not available!'
                        }) 
                    
                    
                }
            })
            
        }
        // Api('get',`${SALES_CUSTOM_JOB}${val}/`).then(res => {
        //     console.log(res)
        //     setjobCardBtnLoader(false)
        //     if(res.id) {
        //         setJobCardObj(res)
        //         setJobCardModal(true)
        //     }
            
        // })
    }

    const fetch_quote_history = (val) => {

        
        Api('get',`${SALES_QUOTE_HOSTORY}${val}/`).then(res => {
            console.log(res)
            if(res.length > 0) {
                setQuoteHistoryInfos(res)
                modalAction(false)
                quoteHistoryModalAction(true)
            }
            
        })
    }

    const fetch_job_custom_card = (val, tabType) =>{
        // fetch_clientList(tabType)
        // fetch_tempClient(tabType)
        setUpdatejobCardBtnLoader(true)
        if(tabType==='pumps'){
            Api('get',`${PUMPJOBCARDS}${jobVal}/`).then(res =>{
                console.log(res)
                if(res && res.id){
                    setUpdatejobCardBtnLoader(false)
                    setJobCardModalType('edit')
                    setJobCardObj(res)
                    setPumpJobCardModal(true)
                }
                else{
                    setUpdatejobCardBtnLoader(false)
                        handleClose()
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Job Card not available!'
                        }) 
                    
                }
            })         

    }
    
        else{
        Api('get',`${NEWJOBCARD_VIEW}${val}/`).then(res =>{
            console.log(res)
            setUpdatejobCardBtnLoader(false)
            if(res && res.id){
                setJobCardModalType('edit')
                setJobCardObj(res)
                setJobCardModal(true)

            }else{
                handleClose()
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Job Card not available!'
                }) 
            
            
        }
        })
        }
        
    }


    const completeReview = (val,act) =>{
        
        const success = () =>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Job Reviewed!',
                showConfirmButton: false,
                timer: 1500
            }) 
        }
        const failed = () =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            }) 
        }
        Api('get',`${SALES_REVIEW}${act}/${val}/`).then(res => {
            if(res.status===200){
                updateQuoteArr(val,act==='accepted'? true : false)
                success()
            }else
                failed()
        })
        modalAction(false)
    }

    const updateReoccuredJob = (job, k, tabType) =>{
        const success = (res) => {
            if(fnRecall)
                fnRecall()
            handleClose();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: `${res}`,
                showConfirmButton: false,
                timer: 1500
            })
            
        }
 
        const failed = () => {
            handleClose();
         Swal.fire({
             icon: 'error',
             title: 'Oops...',
             text: 'Something went wrong!'
         })
        }
        if(k!=='start'){
            modalAction(false)
        Swal.fire({
            title: 'Are you sure?',
            text: k==='pause' ? 'Reoccuring will be interrupted.' : 'Reoccuring will be stopped, you cannot revert this.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: `Yes, ${k} it!`
        }).then((result) => {
            if (result.value) {
                update_jobs_reoccur(tabType, job, k,success, failed)
                
            }
            else{
                modalAction(true)
            }
        });
        }
        else {
            update_jobs_reoccur(tabType, job, k,success, failed)   
        }
 
    }

    const updateReoccuredJobFrq = (job, jobVal, tabType) =>{
        const success = (res) => {
            if(fnRecall)
                fnRecall()
            handleClose();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: `${res}`,
                showConfirmButton: false,
                timer: 1500
            })
            
        }
 
        const failed = () => {
            handleClose();
         Swal.fire({
             icon: 'error',
             title: 'Oops...',
             text: 'Something went wrong!'
         })
        }

        modalAction(false)
        Swal.fire({
            title: 'Are you sure?',
            text: 'All upcoming quotes will be reoccurring in this new frequency',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: `Yes`
        }).then((result) => {
            if (result.value) {
                var content = new FormData()
                content.append('frequency', frqW)
                content.append('job', jobVal)
                content.append('frequency_change_datetime', frqDate)
                update_jobs_reoccur_frq(job, content, success, failed)
            }
            else{
                modalAction(true)
            }
        });
        

    }

    const calcProgressPrec = (val1, val2, val3, val4=0) =>{
        let val = 0;
        if(moment(val1).isValid()){
            if(val4 === 0)
                val = 1/3;
            else 
                val = 1/4;
        }if(moment(val2).isValid()){
            if(val4 === 0)
                val = 2/3;
            else 
                val = 2/4;
        }if(moment(val3).isValid()){
            if(val4 === 0)
                val = 3/3;
            else 
                val = 3/4;
        if(val4!==0 && moment(val4).isValid()){
            val = 4/4
        }
        }
        return val*100
    }


    const fetchQuoteTemplate = (id,k,val=true) =>{
        const successFx = () =>{
            modalAction(false)
            if(val)
                setMainTemplate(true)
            else 
                setSOWModal(true)

            if(val)
                setSalesQuoteSpinnerBTnIndex(null)
            else 
                setSOWSpinnerBTnIndex(null)
        }
        const failedFx = () =>{
            
            if(val)
                setSalesQuoteSpinnerBTnIndex(null)
            else 
                setSOWSpinnerBTnIndex(null)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong.'
            }) 
        
        }
        fetch_quote_temp(id, successFx, failedFx)
        
        if(val)
            setSalesQuoteSpinnerBTnIndex(k)
        else 
            setSOWSpinnerBTnIndex(k)
    }


    const getTypeOfWaste =(id, k=null)=>{
        setSOWSpinnerBTnIndex(k)
        Api('get',`${SCOPOFWORK_CRUD_ADDITIONAL_INFO}`).then( res => {
            console.log(res)
            setAddInfoList(res);
        })
        Api('get',`${SCOPEOFWORK_CRUD_TYPE_OF_WASTE}`).then( res => {
            console.log(res)
            setTypeofWaste(res);
            fetchQuoteTemplate(id, k, false)
        }
        )
    }

    const addQuoteComment = () =>{
        let postData = new FormData()
        postData.append('comment',comment)
        postData.append('quote',quote.id)
        const success = () => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Comment added!',
                showConfirmButton: false,
                timer: 1500
            })
            setComment('')
            Api('get',`${SALES_QUOTE_COMMENT_URL}get/?quote_id=${quote.id}`).then(res => {
                console.log('quoteComment',res)
                if(res.length > 0) {
                    setQuoteCommentsArr(res)
                }
                
            })
        }
        const failed = () => { 
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong.'
        })  }
        Api('post',`${SALES_QUOTE_COMMENT_URL}add/`,postData,success,failed)
    }

    const editQuoteComment = (id) =>{
        let postData = new FormData()
        postData.append('comment',comment)
        postData.append('quote',quote.id)
        const success = () => {
            setCommentEdit(null)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Comment added!',
                showConfirmButton: false,
                timer: 1500
            })
            setComment('')
            Api('get',`${SALES_QUOTE_COMMENT_URL}get/?quote_id=${quote.id}`).then(res => {
                console.log('quoteComment',res)
                if(res.length > 0) {
                    setQuoteCommentsArr(res)
                }
                
            })
        }
        const failed = () => { 
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong.'
        })  }
        Api('put',`${SALES_QUOTE_COMMENT_URL}${id}/`,postData,success,failed)

    }

    const deleteQuoteComment = (id) => {
        const success = () => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Comment added!',
                showConfirmButton: false,
                timer: 1500
            })
            setComment('')
            setCommentDelete(false)
            Api('get',`${SALES_QUOTE_COMMENT_URL}get/?quote_id=${quote.id}`).then(res => {
                console.log('quoteComment',res)
                if(res.length > 0) {
                    setQuoteCommentsArr(res)
                }
                
            })
        }
        const failed = () => { 
            
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong.'
        })  
        setCommentDelete(false)
    }
        
        Swal.fire({
            title: 'Are you sure?',
            text: 'You wont be able to revert this aciton',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: `Yes, deleted it!`
        }).then((result) => {
            if (result.value) {
                Api('delete',`${SALES_QUOTE_COMMENT_URL}${id}/`).then(success).catch(failed)
                
            }
        });
        
    }

    const totalCompleteTime = (val1, val2) =>{
        var val = moment(val2).diff(val1,'minutes')
        var dyZ = Math.floor(val/24/60)
        var hrZ = Math.floor(val/60%24)
        var minZ = Math.floor(val%60)
        console.log('VAL=>',val,'dyZ=>',dyZ,'hrZ=>',hrZ,'minZ=>',minZ)
        if(moment(val2).diff(val1) > 0)
            return `${dyZ > 0 ? `${dyZ} Days` : ''} ${hrZ > 0 ? `${hrZ} Hours` : ''} ${minZ > 0 ? `${minZ} Minutes` : ''}`
        else 
            return '-'
    }


    return (
        <>
        <Draggable handle="strong" 
        bounds={{top: -parseInt(window.innerHeight/12),
             left: -parseInt(window.innerWidth/2), 
             right: parseInt(window.innerWidth/2), 
             bottom: parseInt(window.innerHeight - (window.innerHeight/6))}} 
        //bounds="parent"
         >

  
       

      <Modal
        className={ commentDelete ? classes.modalLow : classes.modal}
        open={modalStatus}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        disableBackdropClick={jobCardModal||jobCustomCardModal}
        onBackdropClick={()=> { if(jobCardModal){ setJobCardModal(false); }else if(jobCustomCardModal){ setJobCustomCardModal(false); } }}
        hideBackdrop={true}
      >
        
        {  !jobCardModal && !pumpJobCardModal && !jobCustomCardModal ?

          <div className="shdl-quote-modal-layout">
              { jobCardModal||jobCustomCardModal ? null :
                    <strong className="draggerHead">
                        <div style={{ width:'50%' }}>
                            <DragIndicatorRounded style={{ transform:'rotate(-90deg)', cursor:'all-scroll' }} />
                        </div>
                        <div>
                            <CancelRounded style={{ color:'red', cursor:'pointer' }} onClick={handleClose} />
                        </div>
                    </strong>
              }
             {
                 quote && (
                    <div className="shdl-quote-seperation" style={{ zIndex:'5', backgroundColor:'white' }}>
                        
                        <h3>Quote #{quote.job_card_id}</h3>
                        {/* {
                            quote && quote.job_card_code    ? 
                            <h5>({quote.job_card_code})</h5>
                            :   null 
                        } */}
                        <div className="shdl-quotes-special-text-layout">
                            <div className="shdl-quotes-special-text-main">
                                <p style={{ flex: 1 }} >Client</p>
                                <p style={{ flex: 0.5 }}>:</p>
                                <p style={{ flex: 2, fontWeight: 'bold' }}>{quote.client.name ? quote.client.name
                                : quote.client.site_name ? quote.client.site_name : quote.client_name}</p>
                            </div>

                            <div style={{ display:'flex', flexDirection:'column', height:'8vh', justifyContent:'space-between' }}>
                                <div className="shdl-quotes-special-text-border">
                                    <p>{quote.client.type ? quote.client.type==='Permenant' ? 'Permanent' : 'Temporary'
                                    :   quote.client_type==='Permenant' ? 'Permanent' : 'Temporary'}</p>
                                </div>
                                
                            </div>
                            
                        </div>
                        <SpecialText title={'Invoice Amount'} value={`$ ${quote.invoice_amount}`} />
                        <SpecialText title={'Status'} value={quote.status}/>
                        <SpecialText title={'Tab Type'} value={quote.tab_type}/>
                        <SpecialText title={'Waste Type'} value={quote.type_of_waste_str_profile_name ?
                                                                 `${quote.type_of_waste_str_profile_name}(${quote.waste_type_str})` 
                                                                : quote.waste_type_str}/>
                        <SpecialText title={'Frequency Weeks'} value={quote.frequency}/>
                       
                        <div className="shdl-quote-info-main-layout">
                        <p>Quote Sent By</p>
                        <div className="shdl-quote-sender-layout">
                            <img src={HOST_WITHOUT_SLASH + quote.created_by.dp} alt="" />
                            <div className="shdl-quote-sender-infos">
                            <p style={{ fontSize: 16, fontWeight: 'bold' }}>{quote.created_by.name}</p>
                            <p style={{ fontSize: 11, textTransform:'capitalize' }}>{quote.created_by.user_type}</p>
                            <p style={{ fontSize: 11, fontWeight: 'bold' }}>{quote.created_by.contact_number}</p>
                            </div>
                        </div>
                        </div>

                        <div className='shdl-quote-templ-EditBox' style={{ height:'25vh', marginBottom:'2vh' }}>
                            <div className='shdl-quote-templ-EditBoxTitle'>Quote Comments</div>
                            {
                                quoteCommentsArr && quoteCommentsArr.length===0 ? 
                                <div className="emptyDivSchedule" style={{ height:'15vh' }}>
                                    <ErrorRounded style={{ fontSize: 30 }} />
                                    <p>No Comments</p>
                                </div>
                            :
                            quoteCommentsArr.map((item, key) => {
                                    return (
                                        <div key={key} className="shdl-quotes-comments-item" style={{ position : 'relative' }} >
                                            <div style={{ position:'absolute', cursor:'pointer', right:0 }}>
                                                <EditRounded onClick={()=>{ setComment(item.comment); setCommentEdit(item.id) }} style={{ fontSize: 20 }}/>
                                                <DeleteForever onClick={()=>{ setCommentDelete(true)
                                                    deleteQuoteComment(item.id); }} style={{ fontSize: 20 }}/>
                                            </div>
                                            <p>{item.comment}</p>
                                            {/* <p style={{ fontSize: 9, fontWeight: 'bold' }}>{item.employee}</p> */}
                                        </div>
                                    )
                                })
                            }
                            <div className="shdl-comment-main">
                                <input 
                                    type="text"
                                    placeholder="Comment here"
                                    value={comment}
                                    onChange={txt => setComment(txt.target.value)}
                                />
                                <IconButton onClick={() => {
                                    if(commentEdit!==null) editQuoteComment(commentEdit)
                                    else addQuoteComment() }} >
                                    <SendIcon />
                                </IconButton>
                            </div>
                        </div>
                        {
                            fetch_quote_temp!==null ?
                        <div className='shdl-quote-templ-EditBox'>
                            <div className='shdl-quote-templ-EditBoxTitle'>Quote Edit Options</div>
                            <div className='shdl-quote-templ-EditBoxContent'>
                                
                                <div className='shdl-quote-templ-EditBoxBtn'
                                    onClick={()=> {
                                        if(quote)
                                            setJobId(quote.id)
                                        fetchQuoteTemplate(quote.id, true)
                                    }}>
                                        {
                                            salesQuoteSpinnerBtnIndex!==null ?
                                                <CircularProgress  style={{ margin: 0, padding: 0 }} size={15}/>
                                            :   'View Quote and Edit Scope of Work'
                                        }
                                </div>                                
                                
                                <div className='shdl-quote-templ-EditBoxBtn'
                                    onClick={()=>{
                                        if(quote)
                                            setJobId(quote.id)
                                        getTypeOfWaste(quote.id, true)
                                    }}>
                                        {
                                            sOWSpinnerBtnIndex!==null ?
                                            <CircularProgress  style={{ margin: 0, padding: 0 }} size={15}/>
                                            :   'Edit Quote Pricing'
                                        }
                                </div>
                                
                            </div>
                        </div>
                        :   null 
                        }

                        <Scrollbar style={{ height: '31vh' }}>
                            <div className="shdl-quote-info-box">
                                <h3>Quote Attachments & Quote Files</h3>
                                <div className="shdl-quote-box-grid-files">
                                <button style={{ width: 'auto', padding: '0px 20px' }} onClick={() => window.open(HOST_WITHOUT_SLASH + quote.quote_file, "_blank")} className="shdl-quote-box-item-sp">
                                Quote Document
                                </button>

                                {
                                    quote.attached_files.map((item, key) => {
                                    let file_name_dat = item.url.split('/')
                                    let file_name_dat2 = file_name_dat[file_name_dat.length - 1]
                                    return (
                                        <div  onClick={() => window.open(HOST_WITHOUT_SLASH + item.url, "_blank")}  key={key} className="shdl-quote-box-item"
                                         style={{ width:'auto', marginLeft:'20px', padding:'5px 20px' }}>
                                            <Tooltip title={file_name_dat2} >
                                                <p style={{ width: 'auto'}}>{item.file_name ? item.file_name : item.name ? item.name : file_name_dat2}</p>
                                            </Tooltip>
                                        </div>
                                    )
                                    })
                                }
                                </div>

                                <SpecialText title={'Created On'} value={quote.created_on.split(' ')[0]}/>
                                <div className="shdl-quotes-special-text-main">
                                    <p style={{ flex: 1 }} >Client Response</p>
                                    <p style={{ flex: 0.5 }}>:</p>
                                    <div style={{ flex: 2 }}>
                                        {
                                            quote.received_file && (
                                                <button 
                                                    style={{ height: 35, width: 'auto', padding: '0px 20px' }}
                                                    onClick={() => window.open(HOST_WITHOUT_SLASH + quote.received_file, "_blank")} 
                                                    className="shdl-quote-box-item">
                                                        {quote.file_name ? quote.file_name :
                                                            quote.template_type_name ? quote.template_type_name : quote.received_file.split('/').pop()}
                                                    </button>
                                                
                                            )
                                        }
                                    </div>
                                </div>
                                {
                                    quote.template_response && quote.template_response.map((item, key) => {
                                        return (
                                            <div key={key} className="shdl-quotes-special-text-main">
                                                <p style={{ flex: 1 }} >Client Response</p>
                                                <p style={{ flex: 0.5 }}>:</p>
                                                <div style={{ flex: 2 }}>
                                                    {
                                                        quote.received_file && (
                                                            <button 
                                                                style={{ height: 35, width: 'auto', padding: '0px 20px' }}
                                                                onClick={() => window.open(HOST_WITHOUT_SLASH + item.template_receive_response, "_blank")} 
                                                                className="shdl-quote-box-item">
                                                                    {item.file_name ? item.file_name :
                                                            item.template_type_name !== null ? item.template_type_name : `Attached Doc ${key + 1}`}</button>
                                                            
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }) 
                                }
                                {/* {   job && job.id!=="" ?
                                  :   null
                                } */}
                                <div style={{ display: 'flex' }}>
                                    {
                                        jobCardBtnLoader ? (
                                            <div className="shdl-quotes-special-text-border jobCardButton">
                                                <CircularProgress size={20} />
                                            </div> 
                                        ) : (
                                            <div style={{ cursor: 'pointer' }} className="shdl-quotes-special-text-border jobCardButton" onClick={()=>{ 
                                                
                                                fetch_job_card(quote.id, quote.tab_type) 
                                                
                                                }}>
                                                <p>Job Card</p>
                                            </div>
                                        )
                                    }
                                    <div 
                                        style={{ cursor: 'pointer', marginLeft: 20 }} 
                                        className="shdl-quotes-special-text-border jobCardButton" 
                                        onClick={()=>{ fetch_quote_history(quote.id) }}
                                    >
                                        <p>Quote Access Log</p>
                                    </div>
                                </div>

                            </div>
                        </Scrollbar>
                    </div>
                 )
             }
             {  job || schedule ?
                <div className='maximiseWindowBtn' 
                 onClick={()=> setMaximiseWin(!maximiseWin)}>
                    <span>
                        {
                            maximiseWin ?
                            <ArrowForwardIosRounded/> :
                            <ArrowBackIosRounded />
                        }
                    </span>
                    { maximiseWin ? <p>Job and Schedule Details</p> : null}                
                </div> : null 
             }   
             {  job || schedule ?
             <div style={{ border: 0, padding: 0 }} className={`shdl-quote-seperation ${maximiseWin ? 'maximiseWindow' : 'minimiseWindow'}`}>
                {/* <Scrollbar style={{ height:'75vh'}}> */}

                    {
                        job && (
                            <div className="shdl-quote-job-layout">
                                <h3>Job #{job.id}</h3>
                                <SpecialText title={'Created On'} value={job.created_on}/>
                                {/* <SpecialText title={'Job Type'} value={job.type}/> */}
                                <SpecialText title={'Tab Type'} value={job.tab_type}/>
                                {
                                    job.schedule_status ? (
                                    <div className="shdl-quotes-special-text-border">
                                        <p>Scheduled</p>
                                    </div>
                                    ) : (
                                        <div className="shdl-quotes-special-text-border">
                                            <p>Not Scheduled</p>
                                        </div>
                                    )
                                }
                                
                            </div>
                        )
                    }
                    
                    <div className="quote-sales-approval-action" style={{ paddingBottom:'20px' }}>
                        <p style={{ fontSize: 18, fontWeight: 'bold' }}>Information for the sales team:</p>

                        {
                           schedule && schedule.id ? (
                                <p>Review completed. Job is Scheduled so Review can't be edited</p>
                            ) : (
                                <Fragment>
                                {  quote && !quote.sales_team_review ?
                                    <p>Click the below button to complete the review of this quote.</p>
                                    :   
                                    <p>Click the below button for back to Incomplete status review of this quote.</p>
                                }
                                </Fragment>
                            )
                        }
                        
    
                    {
                        updatejobCardBtnLoader ? (
                            <button style={{ backgroundColor: cl_blue, color: '#fff' }}>
                                <CircularProgress size={20} />
                            </button>
                        ) : (
                            <button style={{ backgroundColor: cl_blue, color: '#fff' }} onClick={() => fetch_job_custom_card(quote.id, quote.tab_type)}>
                                Update Job Card
                            </button>
                        )
                    }

                    {
                        schedule && schedule.id ? (
                        <button style={{ backgroundColor: cl_green, cursor:'pointer', opacity: '0.5' }}>Complete Review</button>
                        ) : (
                        <Fragment>
                        {  quote && !quote.sales_team_review ?
                            <button style={ job && (job.id!==null || job.id!=="") ? { backgroundColor: cl_green, cursor:'pointer' } : { backgroundColor: cl_green, cursor:'default', pointerEvents:'none', opacity:'0.5' }}
                            onClick={()=>completeReview(quote.id, 'accepted')}    >
                                Complete Review</button>
                            :   
                            <button style={ job && (job.id!==null || job.id!=="") ? { backgroundColor: cl_red, cursor:'pointer', color: '#fff' } : { backgroundColor: cl_red, cursor:'default', pointerEvents:'none', opacity:'0.5' }}
                            onClick={()=>completeReview(quote.id, 'rejected')}    >
                                Incomplete Review</button>
                        }
                        </Fragment>
                        )
                    }
                    </div>
                    {   quote && schedule && quote.reoccurring && schedule.status!=='completed' ?
                    ((quote.parent_quote_id===null || quote.parent_quote_id==='') && quote.recurring_status!=='stop') ||
                    ((quote.parent_quote_id!==null && quote.parent_quote_id!=='') && parentQuoteStatus!=='stop')  ?
                    <div className="quote-sales-approval-action" style={{ paddingBottom:'20px', paddingLeft:'2%' }}>
                        <p style={{ fontSize: 18, fontWeight: 'bold' }}>Options for Reoccuring Quote:</p>

                        <button className="shdl-jobs-action-btn-activeExtraBtn" 
                            onClick={() => { updateReoccuredJob(parentQuoteId!==null ? parentQuoteId : quote.id, 'stop',quote.tab_type); }}
                            style={{ backgroundColor: 'var(--cl_red)', color: 'white' }}>Stop Reoccuring Forever</button>
                        
                        {   parentQuoteStatus!=='pause' ?
                        <button className="shdl-jobs-action-btn-activeExtraBtn" 
                            onClick={() => { updateReoccuredJob(parentQuoteId!==null ? parentQuoteId : quote.id, 'pause',quote.tab_type); }}
                            style={{ backgroundColor: 'var(--cl_blue)', color: 'white' }}>Pause Reoccuring for now</button>
                        :   
                        <button className="shdl-jobs-action-btn-activeExtraBtn" 
                            onClick={() => { updateReoccuredJob(parentQuoteId!==null ? parentQuoteId : quote.id, 'start',quote.tab_type); }}
                            style={{ backgroundColor: 'var(--cl_green)', color: 'white' }}>Resume Reoccuring for now</button>
                        }
                    {   !openFrq ?
                        <div className="shdl-jobs-frqDiv" style={{ border:'none', padding:'0', marginLeft : '1%', paddingLeft:'1%' }}>
                            <button className="shdl-jobs-action-btn-activeExtraBtn" 
                            onClick={() => { setOpenFrq(true); setFrqW(quote.frequency); }}
                            style={{ backgroundColor: 'var(--cl_dark_orange)', color: 'white' }}>Change Frequency</button>
                        
                        </div>
                        :
                        <div className="shdl-jobs-frqDiv">
                            <p>Change Frequency Weeks</p>
                            <div className="reoccurModalTileHead">
                            <InputField
                                title="Frequency (weeks)"
                                mandatory={true}
                                value={frqW}
                                permEdit={true}
                                onChange={val =>{
                                    if(val.target.value===''|| val.target.value===0 || val.target.value==='0')
                                        setFrqW('')
                                    else if(NUMBER_VALIDATION_REGEX.test(val.target.value))
                                        setFrqW(val.target.value.toUpperCase())
                                    }} 
                            />
                            
                            <InputField
                                title="Start Date"
                                type='date'
                                mandatory={true}
                                value={frqDate}
                                disablePast={true}
                                permEdit={true}
                                onChange={val =>{
                                    setFrqDate(val)    
                                }} 
                            />
                            
                            </div>
                            
                            <button className="shdl-jobs-action-btn-activeExtraBtn" 

                            onClick={() => { 
                                if(frqW!=='' && frqW!=='0' && frqW!==0 && frqDate!=='' && NUMBER_VALIDATION_REGEX.test(frqW))
                                    {
                                        updateReoccuredJobFrq(quote.id, job && job.id, quote.tab_type); 
                                        setOpenFrq(false);
                                    } }}
                            style={{ backgroundColor: 'var(--cl_blue)', color: 'white', minWidth: 'auto',
                            opacity:    frqW!=='' && frqW!=='0' && frqW!==0 && frqDate!=='' && NUMBER_VALIDATION_REGEX.test(frqW) ?
                                    1 : 0.5
                            }}>Change Frequency</button>
                        
                        </div>
                    }
                    </div>
                    :   null
                    :   null
                    }
                    {   schedule && schedule.schedule_completed_mail ?
                            <div className="shdl-quote-box-grid-files" style={{ justifyContent:'space-between', flexWrap:'wrap' }}>
                                <button style={{ width: 'auto', padding: '0px 20px', marginTop:'1%' }} onClick={() => window.open(HOST_WITHOUT_SLASH+schedule.schedule_completed_mail, "_blank")} className="shdl-quote-box-item-sp">
                                    Job Completion Mail
                                </button>
                                <button style={{ width: 'auto', padding: '0px 20px', backgroundColor:'var(--cl_blue)', marginRight:'1%', fontWeight:700, marginTop:'1%' }} 
                                 className="shdl-quote-box-item-sp">
                                    <p>Total time to complete :</p>
                                    <p>
                                    {
                                        schedule ?
                                        totalCompleteTime(
                                            schedule.depart_enviro_facility && moment(schedule.depart_enviro_facility).isValid() ? schedule.depart_enviro_facility
                                        :   schedule.start_job,
                                        schedule.arrive_enviro_facility && moment(schedule.arrive_enviro_facility).isValid() ? schedule.arrive_enviro_facility
                                        :   schedule.depart_waste_depot && moment(schedule.depart_waste_depot).isValid() ? schedule.depart_waste_depot
                                        :   schedule.arrive_at_waste_depot && moment(schedule.arrive_at_waste_depot).isValid() ? schedule.arrive_at_waste_depot
                                        :   schedule.completed && moment(schedule.completed).isValid() ? schedule.completed
                                        :   schedule.finish_job
                                        )
                                        :
                                        null
                                    }
                                    </p>
                                </button>
                            </div>
                            :   null
                        }
                    {   (schedule && 
                            ((schedule.extracted_waste_type && schedule.extracted_waste_type!=='')    ||
                            (schedule.purchase_order && schedule.purchase_order!=='') ||
                            (schedule.extracted_litres_of_waste && schedule.extracted_litres_of_waste!=='')))   ?
                            <div className='shdl-quote-chngValBoxes'>
                                {   schedule.purchase_order && schedule.purchase_order!=='' ?
                                <p>
                                    <b>Purchase Order</b> : {schedule.purchase_order}
                                </p>
                                :   null
                                }
                                {   schedule.extracted_waste_type && schedule.extracted_waste_type!=='' ?
                                <p>
                                    <b>Change in Type of Waste</b> : {schedule.extracted_waste_type}
                                </p>
                                :   null
                                }
                                {   schedule.extracted_litres_of_waste && schedule.extracted_litres_of_waste!=='' ?
                                <p>
                                    <b>Change in Waste Litres</b> : {schedule.extracted_litres_of_waste}
                                </p>
                                :   null
                                }
                            </div>
                            :   null
                        }
                    {
                       schedule && schedule.id && (
                            <div className="shdl-quote-schedule-layout">
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h3>Schedule #{schedule.id}</h3>
                                    <div className="shdl-quotes-special-text-border">
                                        <p>
                                            {
                                            schedule.status==="pending" ? 'Pending'     :
                                            schedule.status==='departed_enviro_facility' ? 'Departed Enviro Facility' :
                                            schedule.status==='job_started' ? 'Job Started' :
                                            schedule.status==='job_finished' ? 'Job Finished' :
                                            schedule.status==='arrived_at_waste_depot' ? 'Arrived At Waste Depot' :
                                            schedule.status==='departed_waste_depot' ? 'Departed Waste Depot' :
                                            schedule.status==='arrived_at_enviro_facility' ? 'Arrived At Enviro Facility' :
                                            'Completed'
                                            }
                                        </p>
                                    </div>
                                </div>

                                <div className="shdl-quote-timing-layout">
                                    <div className="shdl-quote-timing-seperation">
                                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                            <h4>Start Date</h4>
                                            <p>{moment(schedule.start_date).format('DD-MM-YYYY')}</p>
                                        </div>
                                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                            <h4>Start Time</h4>
                                            <p>{schedule.start_time}</p>
                                        </div>
                                    </div>
                                    <p>to</p>
                                    <div className="shdl-quote-timing-seperation">
                                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                            <h4>End Date</h4>
                                            <p>{moment(schedule.end_date).format('DD-MM-YYYY')}</p>
                                        </div>
                                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                            <h4>End Time</h4>
                                            <p>{schedule.end_time}</p>
                                        </div>
                                    </div>
                                </div>
                                {   schedule && (
                                    schedule.depart_enviro_facility || schedule.start_job || schedule.finish_job ||
                                    schedule.arrive_enviro_facility || schedule.depart_waste_depot || schedule.arrive_at_waste_depot ||
                                    schedule.completed
                                ) ? 
                                        <div className="shdl-quote-timing-layout-driver">
                                            <div className='shdl-quote-timing-layout-driver-title'>
                                                <h3>Prime Vehicle Driver Job Status</h3>
                                            </div>
                                            <div className='shdl-quote-timing-layout-driver-content'>
                                                <div className={ calcProgressPrec(schedule.depart_enviro_facility,schedule.start_job,schedule.finish_job,schedule.completed) <=1 ?
                                                    `shdl-quote-timing-layout-layerOne` : `shdl-quote-timing-layout-layerOneEndLine`}>
                                                    <div className='shdl-quote-timing-layout-layerOne-progress'
                                                        style={{
                                                            width : `${calcProgressPrec(schedule.depart_enviro_facility,schedule.start_job,schedule.finish_job,schedule.completed)}%`
                                                        }}
                                                    ></div>
                                                    
                                                    <div className={`shdl-quote-timing-layout-stepCircle${moment(schedule.depart_enviro_facility).isValid() ? '' : '-invalid'}`}>
                                                        <h4>Depart Enviro Facility</h4>
                                                        <p>{ moment(schedule.depart_enviro_facility).isValid() ? 
                                                        moment(schedule.depart_enviro_facility).format('DD-MM-YYYY') : '--'}</p>
                                                        <p>{ moment(schedule.depart_enviro_facility).isValid() ? 
                                                        moment(schedule.depart_enviro_facility).format('HH:mm a') : '--'}</p>
                                                    </div>
                                                    <div className={`shdl-quote-timing-layout-stepCircle${moment(schedule.start_job).isValid() ? '' : '-invalid'}`}>
                                                        <h4>Job Started</h4>
                                                        <p>{ moment(schedule.start_job).isValid() ? 
                                                        moment(schedule.start_job).format('DD-MM-YYYY') : '--'}</p>
                                                        <p>{ moment(schedule.start_job).isValid() ? 
                                                        moment(schedule.start_job).format('HH:mm a') : '--'}</p>
                                                    </div>
                                                    <div className={`shdl-quote-timing-layout-stepCircle${moment(schedule.completed).isValid() ? '' : '-invalid'}`}>
                                                    
                                                        <h4>Job Finished</h4>
                                                        <p>{ moment(schedule.completed).isValid() ? 
                                                        moment(schedule.completed).format('DD-MM-YYYY') : '--'}</p>
                                                        <p>{ moment(schedule.completed).isValid() ? 
                                                        moment(schedule.completed).format('HH:mm a') : '--'}</p>
                                                    </div>                                                    
                                                    <div className='shdl-quote-timing-layout-layerOne-progressEnd'
                                                    style={{
                                                        width : 0
                                                    }}></div>
                                                </div>
                                                <div className={
                                                    calcProgressPrec(schedule.arrive_enviro_facility,schedule.depart_waste_depot,schedule.arrive_at_waste_depot)
                                                    < 30 ?
                                                    `shdl-quote-timing-layout-layerTwo` : `shdl-quote-timing-layout-layerTwoEndLine`}>
                                                    <div className='shdl-quote-timing-layout-layerTwo-progress'
                                                    style={{
                                                        width : `${calcProgressPrec(schedule.arrive_enviro_facility,schedule.depart_waste_depot,schedule.arrive_at_waste_depot)}%`
                                                    }}
                                                    ></div>
                                                    <div className={`shdl-quote-timing-layout-stepCircle${moment(schedule.arrive_enviro_facility).isValid() ? '' : '-invalid'}`}>
                                                        <h4>Arrive Enviro Facility</h4>
                                                        <p>{ moment(schedule.arrive_enviro_facility).isValid() ? 
                                                        moment(schedule.arrive_enviro_facility).format('DD-MM-YYYY') : '--'}</p>
                                                        <p>{ moment(schedule.arrive_enviro_facility).isValid() ? 
                                                        moment(schedule.arrive_enviro_facility).format('HH:mm a') : '--'}</p>
                                                    </div>
                                                    <div className={`shdl-quote-timing-layout-stepCircle${moment(schedule.depart_waste_depot).isValid() ? '' : '-invalid'}`}>
                                                        <h4>Depart Waste Depot</h4>
                                                        <p>{ moment(schedule.depart_waste_depot).isValid() ? 
                                                        moment(schedule.depart_waste_depot).format('DD-MM-YYYY') : '--'}</p>
                                                        <p>{ moment(schedule.depart_waste_depot).isValid() ? 
                                                        moment(schedule.depart_waste_depot).format('HH:mm a') : '--'}</p>
                                                    </div>
                                                    
                                                    <div className={`shdl-quote-timing-layout-stepCircle${moment(schedule.arrive_at_waste_depot).isValid() ? '' : '-invalid'}`}>
                                                        <h4>Arrive Waste Depot</h4>
                                                        <p>{ moment(schedule.arrive_at_waste_depot).isValid() ? 
                                                        moment(schedule.arrive_at_waste_depot).format('DD-MM-YYYY') : '--'}</p>
                                                        <p>{ moment(schedule.arrive_at_waste_depot).isValid() ? 
                                                        moment(schedule.arrive_at_waste_depot).format('HH:mm a') : '--'}</p>
                                                    </div>
                                                    <div className='shdl-quote-timing-layout-layerTwo-progressEnd'
                                                    style={{
                                                        width : 0
                                                    }}></div>
                                                </div>

                                            </div>
                                            
                                        </div>
                                        : null
                                    }
                                     

                                <p style={{ fontWeight:'500' }}>Primary Vehicle and Driver</p>
                                    <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start', marginTop:'1%' }}>
                                            {schedule.vehicle ?
                                                <div className="shdl-quote-info-main-layout" 
                                                style={{ padding: 5, margin: '0 1%', width: '15vw', border:'1px solid rgba(0,0,0,0.3)' }} >
                                                    <div className="shdl-quote-vehicle-layout">
                                                        <img src={HOST_WITHOUT_SLASH + schedule.vehicle.image} alt="" />
                                                        <div className="shdl-quote-sender-infos">
                                                            <p style={{ fontSize: 11 }}>{'Vehicle info'}</p>
                                                            <p style={{ fontSize: 16, fontWeight: 'bold' }}>{schedule.vehicle.registration}</p>
                                                            <p style={{ fontSize: 11, textTransform: 'capitalize' }}>{schedule.vehicle.type}</p>
                                                            
                                                        </div>
                                                    </div>
                                                </div> : null
                                            }
                                            {schedule.prime_vehicle_driver ?
                                                <div className="shdl-quote-info-main-layout" 
                                                style={{ padding: 5, margin: '0 1%', width: '15vw', border:'1px solid rgba(0,0,0,0.3)' }} >
                                                    <div className="shdl-quote-vehicle-layout">
                                                        <img src={HOST_WITHOUT_SLASH + schedule.prime_vehicle_driver[0].dp} alt="" />
                                                        <div className="shdl-quote-sender-infos">
                                                            <p style={{ fontSize: 11 }}>{'Driver info'}</p>
                                                            <p style={{ fontSize: 16, fontWeight: 'bold' }}>{schedule.prime_vehicle_driver[0].name}</p>    
                                                            <p style={{ fontSize: 11, textTransform: 'capitalize' }}>{schedule.prime_vehicle_driver[0].designation}</p>                                                        
                                                        </div>
                                                    </div>
                                                </div> : null
                                            }
                                    </div>
                                {/* <Scrollbar style={{ height: '25vh' }}> */}
                                    <div className="shdl-quote-team-layout">
                                        <div className="shdl-quote-tam-seperation">
                                            <p style={{ fontWeight:'500' }}>Team Members</p>
                                            {
                                                schedule.additional_vehicle_driver.map((item, key) => {
                                                    return (
                                                        <div key={key} className="shdl-quote-team-list-layout" style={{ position:"relative" }}>
                                                            <img src={HOST_WITHOUT_SLASH + item.dp} alt="" />
                                                            <div className="shdl-quote-sender-infos">
                                                                <p style={{ fontSize: 11 }}>{'Additonal Driver'}</p>
                                                                <p style={{ fontSize: 16, fontWeight: 'bold' }}>{item.name}</p>
                                                                <p style={{ fontSize: 16, fontWeight: 'bold' }}>Vehicle : {item.registration}</p>
                                                                
                                                                <p style={{ fontSize: 11, textTransform:'capitalize' }}>{item.designation}</p>
                                                            </div>
                                                            <div style={{ position: 'absolute', borderRadius:'20%', backgroundColor:'black', display:"flex", alignItems:'center', justifyContent:'center', padding:'1%', right:0, top:0 }}>
                                                                <DirectionsBusRounded style={{ cursor: 'default', color:'white', fontSize:'1rem' }} />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                schedule.team.map((item, key) => {
                                                    return (
                                                        <div key={key} className="shdl-quote-team-list-layout">
                                                            <img src={HOST_WITHOUT_SLASH + item.dp} alt="" />
                                                            <div className="shdl-quote-sender-infos">
                                                                <p style={{ fontSize: 11 }}>{'Team Member'}</p>
                                                                <p style={{ fontSize: 16, fontWeight: 'bold' }}>{item.name}</p>
                                                                <p style={{ fontSize: 11, textTransform:'capitalize' }}>{item.designation}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="shdl-quote-tam-seperation">
                                                <div className="shdl-quote-gallary-tabs">
                                                    <Fragment>
                                                        <button 
                                                            className={ GalleryTab===1 ? "shdl-quote-gallary-btn-active" : "" }
                                                            onClick={() => {
                                                                if(GalleryTab!==1)
                                                                    setGalleryTab(1)
                                                                }}>
                                                                Image Gallery</button>
                                                                <button 
                                                            className={ GalleryTab===4 ? "shdl-quote-gallary-btn-active" : "" }
                                                            onClick={() => {
                                                                if(GalleryTab!==4)
                                                                    setGalleryTab(4)
                                                                }}>
                                                                Video Gallery</button>
                                                        <button 
                                                            className={ GalleryTab===2 ? "shdl-quote-gallary-btn-active" : ""}
                                                            onClick={() => {
                                                                if(GalleryTab!==2)
                                                                    setGalleryTab(2)
                                                                    if(schedule.comments && schedule.comments?.length > 0 && commentRefreshFn!==null){
                                                                        updateCommentsSection_fn(schedule?.comments[schedule?.comments?.length-1]?.id, commentRefreshFn)
                                                                    }
                                                                }}>
                                                                Comments</button>
                                                        <button 
                                                            className={ GalleryTab===3 ? "shdl-quote-gallary-btn-active" : ""}
                                                            onClick={() => {
                                                                if(GalleryTab!==3)
                                                                    setGalleryTab(3)

                                                                }}>
                                                                Signature</button>
                                                    </Fragment>

                                                </div>
                                                {
                                                    GalleryTab===1 ?
                                                    <>
                                                        {
                                                            schedule.before_pics && schedule.before_pics.length!==0 &&
                                                        <div className='shdl-quotes-gallery-splPicsChip'>
                                                            Before Job Images
                                                        </div>
                                                        }
                                                        {
                                                            schedule.before_pics && schedule.before_pics.length!==0 &&
                                                                <div className="shdl-quotes-gallery-grid">
                                                                    {
                                                                        schedule.before_pics && schedule.before_pics.map((item, key) => {
                                                                            return (
                                                                                <div className='shdl-quotes-gallery-gridImg-beforePic'>
                                                                                <img 
                                                                                onClick={() => window.open(HOST_WITHOUT_SLASH + item.image, "_blank")} key={key} src={HOST_WITHOUT_SLASH + item.image} alt="" />
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                        }
                                                        {
                                                            schedule.after_pics && schedule.after_pics.length!==0 &&
                                                            <div className='shdl-quotes-gallery-splPicsChip' style={{ backgroundColor:'var(--cl_blue)' }}>
                                                                After Job Images
                                                            </div>
                                                        }
                                                        {
                                                            schedule.after_pics && schedule.after_pics.length!==0 &&
                                                                <div className="shdl-quotes-gallery-grid">
                                                                    {
                                                                        schedule.after_pics && schedule.after_pics.map((item, key) => {
                                                                            return (
                                                                                <div className='shdl-quotes-gallery-gridImg-afterPic'>
                                                                                <img  
                                                                                onClick={() => window.open(HOST_WITHOUT_SLASH + item.image, "_blank")} key={key} src={HOST_WITHOUT_SLASH + item.image} alt="" />
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                        }
                                                        {
                                                            <div>
                                                                <button className='' style={{padding:10}}  onClick={handleOpenReportModal}>
                                                                Generate Report
                                                                </button>
                                                                
                                                            </div>
                                                        }
                                                        {
                                                            schedule.gallery && schedule.gallery.length!==0 &&
                                                            <div className='shdl-quotes-gallery-splPicsChip' style={{ backgroundColor:'var(--cl_grey)' }}>
                                                                Other Images
                                                            </div>
                                                        }
                                                        {
                                                            schedule.gallery && schedule.gallery.length===0 &&
                                                            schedule.job_video && schedule.job_video!=="" && schedule.job_video.length===0 &&
                                                            schedule.before_pics && schedule.before_pics.length===0 &&
                                                            schedule.after_pics && schedule.after_pics.length===0 ? 
                                                             
                                                            <div className="emptyDivSchedule">
                                                                <ErrorRounded style={{ fontSize: 30 }} />
                                                                <p>No Files Shared</p>
                                                            </div>
                                                        :   schedule.gallery && schedule.gallery.length!==0 ?  
                                                            <div className="shdl-quotes-gallery-grid">
                                                                {
                                                                    schedule.gallery && schedule.gallery.map((item, key) => {
                                                                        return (
                                                                            <div>
                                                                            <img onClick={() => window.open(HOST_WITHOUT_SLASH + item.image, "_blank")} key={key} src={HOST_WITHOUT_SLASH + item.image} alt="" />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        :   null
                                                        }
                                                        
                                                        
                                                    </>
                                                    :   GalleryTab===4 ?
                                                    <>
                                                        {
                                                            schedule.job_video && schedule.job_video.length!==0 ?
                                                                <div className="shdl-gallery-grid-vid">
                                                                    {
                                                                        schedule.job_video && schedule.job_video.map((item, key) => {
                                                                            return (
                                                                                <div key={key} className="shdl-img-PicsDiv-vid" style={{ border:'none' }}>
                                                                                    <VideoFilePreview
                                                                                        videoSrc={item.video}
                                                                                        // thumbStyle={{ height: 40, width: 50, borderRadius: 5 }}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                :
                                                                <div className="emptyDivSchedule">
                                                                <ErrorRounded style={{ fontSize: 30 }} />
                                                                <p>No Video Footages shared</p>
                                                            </div>
                                                        }
                                                    </>
                                                    :   GalleryTab===2 ?
                                                    <>
                                                        {
                                                            schedule.comments && schedule.comments.length===0 ? 
                                                            <div className="emptyDivSchedule">
                                                                <ErrorRounded style={{ fontSize: 30 }} />
                                                                <p>No Comments</p>
                                                            </div>
                                                        :
                                                        <Fragment>
                                                            {
                                                                schedule.comments.map((item, key) => {
                                                                return (
                                                                    <div key={key} className="shdl-quotes-comments-item">
                                                                        <p>{item.comment}</p>
                                                                        <p style={{ fontSize: 9, fontWeight: 'bold' }}>{item.employee}</p>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        
                                                        </Fragment>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            schedule.signature_name && schedule.signature_name!=='' &&
                                                            <div className='shdl-quotes-gallery-splPicsChip' style={{ backgroundColor:'var(--cl_blue)' }}>
                                                                {schedule.signature_name}
                                                            </div>
                                                        }
                                                        {
                                                            schedule.signature && schedule.signature!=='' ? 
                                                            <div className="shdl-quotes-gallery-grid" style={{ gridTemplateColumns : 'auto' }}>
                                                                <img onClick={() => window.open(HOST_WITHOUT_SLASH + schedule.signature, "_blank")} style={{ height:'5rem' }}
                                                                src={HOST_WITHOUT_SLASH + schedule.signature} alt="" />
                                                            </div>
                                                        :
                                                            <div className="emptyDivSchedule">
                                                                <ErrorRounded style={{ fontSize: 30 }} />
                                                                <p>No Signatures added</p>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                
                                                
                                            
                                        </div>
                                    </div>
                                {/* </Scrollbar> */}
                                
                            </div>
                        )

                    }
                    {/* </Scrollbar> */}

                </div>  :   null
            }
            {
            //Handle Modal
            isReportModalOpen && <ReportModal  isOpen={isReportModalOpen} onClose={handleCloseReportModal} schedule={schedule} />}
          </div>

          :
          jobCardModal    ?
       
            <NewJobCard setJobCardModal={setJobCardModal} 
                jobObj={jobCardObj} modalType={jobCardModalType} 
                tabType={quote.tab_type} clientList={null}/>
          :
          null

        }
        
     
        
        </Modal>
        </Draggable>
        </>
  );
}


const mapDispatchToProps = (dispatch) =>{
    return{
        fetch_clientList : (tabType) => dispatch(fetch_client_list(tabType)),
        update_jobs_reoccur: (type, id, key, success, failed) => dispatch(updateJobReoccurList(type, id, key, success, failed)),
        updateQuoteArr: (id, val) => dispatch({ type: 'UPDATE_QUOTE_REGISTER', id : id, status : val }),
        update_jobs_reoccur_frq: (id, content, success, failed) => dispatch(updateJobReoccurFrqList(id, content, success, failed)),
        updateCommentsSection_fn: (id, success) => dispatch(updateCommentsSection(id, success)),
        updateCertOfDestJobCard_fn: (id, success) => dispatch(updateCertOfDestJobCard(id, success)),
        fetch_tempClient : (tabType) => dispatch(fetch_temp_clients(tabType))
    }
}


 export default connect( null, mapDispatchToProps) (TransitionsModal)
 
