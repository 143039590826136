import { SCHEDULE_JOB_VIEW, NEWJOBCARD_ADD, NEWJOBCARD_VIEW, NEWJOBCARD_EDIT, PUMPJOBCARDS, NEWJOBCARD_RENAME } from "../../store/endpoint"
import Api from '../../store/api'

export const sales_job_view = (id) => {
    return function (dispatch){
        console.log("CALLED JOB VIEW", SCHEDULE_JOB_VIEW, id)
        let get_url = `${SCHEDULE_JOB_VIEW}${id}/`
        Api('get', get_url).then(response => {
            console.log("Single Job View",response)
            if(response){
                dispatch({ type: 'SALES_JOB_VIEW', selectedJob: response })
            }
        })
    }
}

export const refresh_job_view = () => {
    return function (dispatch) {
        dispatch({ type: 'REFRESH_JOB_VIEW' })
    }
}


export const listJobCards = (tbType = '', pageNo) =>{
    return function (dispatch) {
        if(tbType === 'pumps')
        {
            Api('get', PUMPJOBCARDS).then(response => {
                console.log(response)
                dispatch({
                    type    :   'GET_JOBCARDS_LISTPAGING',
                    jobs    :   response,
                    page    :   pageNo
                })
            })
        }
        else{
            Api('get',`${NEWJOBCARD_VIEW}?page=${pageNo}&limit=10`).then(response => {
                console.log('TBTYPE:',tbType,'PGNo:',pageNo,response)
                dispatch({
                    type    :   'GET_JOBCARDS_LISTPAGING',
                    jobs    :   response,
                    page    :   pageNo
                })
            })
        }
    }
}

export const searchJobCard = (key) =>{
    return function (dispatch) {
        if(key!==''){
            Api('get',`${NEWJOBCARD_VIEW}?key=${key}`).then(response => {
                console.log(key,'<=KEY',response)
                dispatch({
                    type    :   'GET_JOBCARDS_LISTPAGING',
                    jobs    :   response,
                    page    :   1
                })
            })
        }
        else{
            Api('get',`${NEWJOBCARD_VIEW}?page=1&limit=10`).then(response => {
                console.log(response)
                dispatch({
                    type    :   'GET_JOBCARDS_LISTPAGING',
                    jobs    :   response,
                    page    :   1
                })
            })
        }
    }
}

export const addNewJobCard = (data, success, failed) =>{
    for(var k of data.entries())
        console.log(k[0]," ",k[1])
    return function (dispatch) {
        // const success_fx = (res) =>{
        //     // dispatch(listJobCards(''))
        //     success()
        // }
        Api('post',NEWJOBCARD_ADD, data, success, failed)
    }
}

export const addNewPumpJobCard = (data, success, failed) =>{
    return function (dispatch) {
        const success_fx = (res) =>{
            console.log(res)
            dispatch(listJobCards('pumps',1))
            success(res)
        }
        console.log('Type of=> ', data)
        Api('post', PUMPJOBCARDS, data, success_fx, failed)

    }
}


export const editNewJobCard = (data, jobId, success, failed) =>{
    return function (dispatch) {
        const success_fx = (res) =>{
            // dispatch(listJobCards('waste',1))
            success()
        }
        Api('put-json',`${NEWJOBCARD_EDIT}${jobId}/`, data, success_fx, failed)
    }
}

export const editNewPumpJobCard = (data, jobId, success, failed) =>{
    return function (dispatch) {
        const success_fx = (res) =>{
            dispatch(listJobCards('pumps',1))
            success()
        }
        Api('put-json',`${PUMPJOBCARDS}${jobId}/`, data, success_fx, failed)
    }
}

export const editNewJobCardName = (data, jobId, tabType, success, failed) =>{
    return function (dispatch) {
        // const success_fx = (res) =>{
        //     // dispatch(listJobCards(tabType!=='waste' ? tabType : '',1))
        //     success()
        // }
        Api('post',`${NEWJOBCARD_RENAME}${tabType}/${jobId}/`, data, success, failed)
    }
}
