import React,{useEffect,useState,useMemo} from 'react'
import { Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import './styles.css'
import { CancelRounded } from '@material-ui/icons'
import ScrollBar from '../ImprovedScrollBar'
import Api from '../../store/api'
import { GET_DETAILS,GENERATE_DETAILS,ADD_DETAILS } from '../../store/endpoint'


const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    clickableImage:{
      cursor:'pointer',
    },
    disabledButton: {
      backgroundColor: 'gray', 
      color: 'lightgray',
      cursor: 'not-allowed', 
    },
  }));



const ReportModal = ({isOpen,onClose,schedule}) => {
    const classes = useStyles();
    const [images,setImages] = useState(null)
    const [isDownloading, setIsDownloading] = useState(false);
    const [isRequesting, setIsRequesting] = useState(false) 
    const [isDocxGenerated,setIsDocxGenerated] = useState(false)
    
     

    const handleImageClick = (imageSrc) =>{
      window.open(imageSrc, '_blank');
    }


    

    const handleDownloadDocx = async () => {

      //setIsLoading(true);
      setIsDownloading(true);
  
      try {
  
        const response = await fetch(`${GENERATE_DETAILS}${schedule.id}`); // Replace with your API endpoint
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);  
        const link = document.createElement('a');
  
        link.href = url;
  
        link.setAttribute('download', 'document.docx'); // Set desired filename
  
        document.body.appendChild(link);
  
        link.click();
  
        document.body.removeChild(link);
  
      } catch (error) {
  
        console.error('Error downloading file:', error);
  
      } finally {
  
       // setIsLoading(false);
       setIsDownloading(false);
      
  
      }
  
    };

    const handleCheckboxChange = (imageId, isBefore) => {
      setImages((prevImages) => {
        const updatedImages = { ...prevImages }; 
    
        if (isBefore) {
          updatedImages.before_pics = prevImages.before_pics.map((image) =>
            image.id === imageId
              ? { ...image, show_title: !image.show_title }
              : image
          );
        } else {
          updatedImages.after_pics = prevImages.after_pics.map((image) =>
            image.id === imageId
              ? { ...image, show_title: !image.show_title }
              : image
          );
        }
    
        return updatedImages;
      });
    };
    
    const handleNoteChange = (imageId, note, isBefore) => {
      setImages((prevImages) => {
        const updatedImages = { ...prevImages };
    
        if (isBefore) {
          updatedImages.before_pics = prevImages.before_pics.map((image) =>
            image.id === imageId ? { ...image, print_title: note } : image
          );
        } else {
          updatedImages.after_pics = prevImages.after_pics.map((image) =>
            image.id === imageId ? { ...image, print_title: note } : image
          );
        }
    
        return updatedImages;
      });
    };
  
   
    useEffect(()=>{
     
        const fetchImages = ()=>{
          Api('get',`${GET_DETAILS}${schedule.id}`).then(response=>{
           
           
            if(response)
            {
              
              
              setImages(response)
              
            }
          })
        
        
        }

        fetchImages()
        
     
    },[schedule.id])

    const memoizedData = useMemo(()=> images,[images] )

    const handleSubmit = async () => {
      try {
        
        setIsRequesting(true)
        const dataToSend = {
          images: [
            ...images.before_pics.map((image) => ({
              image_id: image.id,
              show_tittle: image.show_title,
              print_tittle: image.print_title,
            })),
            ...images.after_pics.map((image) => ({
              image_id: image.id,
              show_tittle: image.show_title,
              print_tittle: image.print_title,
            })),
          ],
        };

        
        const success = ()=>{

          setIsRequesting(false)
          setIsDocxGenerated(true)
          
        }
       
        
        Api('post', ADD_DETAILS, dataToSend,success)
        

        // console.log('This is data response:',response)
  
        // // Handle success or failure
        // if (response.status === 200) {
        //   console.log('Data sent successfully');
        //   //onClose(); 
        // } else {
        //   console.error('Error sending data:', response.error);
        // }
      } catch (error) {
        console.error('Error sending data:', error);
      }
    };

   

    return (
        <Modal
        className={classes.modal}
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          open={isOpen}
          onClose={onClose}
          hideBackdrop={true}
          
        >
          
          <div className='report-modal'>
            <div className='report-head'>
                <div>
                    <CancelRounded style={{ color:'red', cursor:'pointer' }} onClick={onClose} />
                </div>
                
                {/* <h2 id="nested-modal-title">Nested Modal</h2>
                <p id="nested-modal-description">This is the content of the nested modal.</p> */}
            </div>
            {console.log("This is parent line")}
            {console.log(images)}
            <div className='report-body'>
              
              <div className='report-body-head'>
              <h1>Generate Report</h1>
              </div>

              <ScrollBar style={{ height: '50vh' }}>

              <BeforeSection images={memoizedData} handleImageClick={handleImageClick} classes={classes} handleCheckboxChange={handleCheckboxChange} handleNoteChange={handleNoteChange} 
              />
              <AfterSection images={memoizedData} handleImageClick={handleImageClick} classes={classes} handleCheckboxChange={handleCheckboxChange} handleNoteChange={handleNoteChange} 
              />
               
            
            </ScrollBar>
            
            <div className='generate-button-div'>
              <button className={`generate-button ${isRequesting ? classes.disabledButton : ''}`} 
              onClick={handleSubmit}
              disabled={isRequesting} 
              >
                {isRequesting ? 'Generating...' : 'Save and Generate'} 
              </button>

              {isDocxGenerated && (       
        <button       
          className={`generate-button ${isDownloading ? classes.disabledButton : ''}`}
          onClick={handleDownloadDocx}
          disabled={isDownloading}
        >
          {isDownloading ? 'Downloading...' : 'Download DOCX'} 
        </button>)}
              
      
            </div>
            
            
            
            
            </div>
            
          </div>
        </Modal>
      )
}

const BeforeSection = ({images,handleImageClick,classes,handleCheckboxChange,handleNoteChange})=>{
  return(
    <>
    <div className='before-images-section'>
                <div className=''>
                  <h2>Before Images</h2>
                </div>
     <div className='before-images'>
                  

                  {console.log("This line happened")}
                  
                  {
                    
                     
                     images?.before_pics && images.before_pics.map((item,key)=>{
                      return(
                        <div className='image-div' key={key} >
                          <div className='image-div-element'>
                          <input type="checkbox"
                          checked={item.show_title} // Corrected spelling of show_title
                          onChange={() => handleCheckboxChange(item.id,true)}
                          /> 
                            </div>
                           {console.log("This line happened")}
                            <div className='image-div-element'>
                            <img className = "image-element"  src={item.file_url}  alt="before_image"
                            onClick={() => handleImageClick(item.file_url)}
                            />
                            </div>
                          <div className='image-div-element'>
                            <textarea 
              placeholder="Add Note" 
              className="image-description"
              value={item.print_title || ''} 
                  onChange={(e) => handleNoteChange(item.id, e.target.value,true)}
            />
                          </div>
                           
                           
                     
                        </div>
                      )
                     })
                     
                     }
                </div>
                </div>
    </>
  )
}

const AfterSection = ({images,handleImageClick,classes,handleCheckboxChange,handleNoteChange})=>{
  return(
    <>
    <div className='before-images-section'>
                <div className='after-image-header-div'>
                  <h2>After Images</h2>
                </div>
     <div className='before-images'>
                  {
                     
                     images?.after_pics && images.after_pics.map((item,key)=>{
                      return(
                        <div className='image-div' key={key} >
                          <div className='image-div-element'>
                          <input type="checkbox"
                          checked={item.show_title} // Corrected spelling of show_title
                          onChange={() => handleCheckboxChange(item.id,false)}
                          /> 
                            </div>
                           
                            <div className='image-div-element'>
                            <img className = "image-element"  src={item.file_url}  alt="before_image"
                            onClick={() => handleImageClick(item.file_url)}
                            />
                            </div>
                          <div className='image-div-element'>
                            <textarea 
              placeholder="Add Note" 
              className="image-description"
              value={item.print_title || ''} 
                  onChange={(e) => handleNoteChange(item.id, e.target.value,false)}
            />
                          </div>
                           
                           
                     
                        </div>
                      )
                     })
                     
                     }
                </div>
                </div>
    </>
  )
}


export default ReportModal